import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
/* import Loading from '../../components/utils/Loading'; */
import Style from './index.module.scss';

const ComposantErreur = props => (
	<div className="text-danger">{props.children}</div>
);

const ComposantInput = ({ field, form: { touched, errors }, ...props }) => (
	<div className="form-group m-0">
		<label> {props.label} </label>
		<input type="text" {...props} className="form-control" {...field} />
	</div>
);

const ComposantTextarea = ({ field, form: { touched, errors }, ...props }) => (
	<div className="form-group">
		<label> {props.label} </label>
		<textarea type="text" {...props} className="form-control" {...field} />
	</div>
);

export class Formulaire extends React.Component {
	submit = (values, actions) => {
		console.log(values);
		setTimeout(() => {
			actions.isSubmitting = false;
			actions.resetForm();
		}, 1000);
	};

	userSchema = Yup.object().shape({
		name: Yup.string('String')
			.min(3, 'Trop court')
			.max(20, 'Trop long')
			.required('Requis'),
		email: Yup.string().email("L'email doit être valide").required('Requis'),
		textarea: Yup.string().required('Requis'),
	});

	render() {
		return (
			<>
				<div
					className={
						'container-fluid py-5 bg-light d-flex flex-column justify-content-center align-items-center ' +
						Style.bgImg
					}>
					<Formik
						onSubmit={this.submit}
						initialValues={{ name: '', email: '', textarea: '' }}
						validationSchema={this.userSchema}>
						{({
							values,
							handleBlur,
							handleChange,
							handleSubmit,
							isSubmitting,
							errors,
							touched,
						}) => (
							<form
								onSubmit={handleSubmit}
								className="bg-white shadow rounded-lg col-lg-4 col-md-6 py-5 px-sm-5 d-flex flex-column">
								<div className="mb-sm-5 text-center">
									<h2>Nous contacter</h2>
									<span className="font-italic font-weight-light">
										(&nbsp;formulaire fictif, aucun message sera envoyé&nbsp;)
									</span>
								</div>
								<Field
									name="name"
									placeholder="Prénom"
									component={ComposantInput}
								/>
								<ErrorMessage name="name" component={ComposantErreur} />
								<Field
									name="email"
									placeholder="Email"
									type="email"
									component={ComposantInput}
								/>
								<ErrorMessage name="email" component={ComposantErreur} />
								<Field
									name="textarea"
									placeholder="Votre message..."
									component={ComposantTextarea}
								/>
								<ErrorMessage name="textarea" component={ComposantErreur} />
								<button
									type="submit"
									className="btn btn-secondary mt-sm-4 ml-auto"
									disabled={isSubmitting}>
									Envoyer
								</button>
							</form>
						)}
					</Formik>
				</div>
			</>
		);
	}
}
