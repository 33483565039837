import React, { Component } from 'react';
import { Header } from './components';
import { Footer } from './components';
import  { Formulaire }  from './features/formulaire';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { fetchFavoris } from './store/actions';
import Style from './App.module.scss';
import Identifaction from './components/login';

const LazyFilms = Loadable({
  loader: () => import('./features/films'),
  loading: () => <div className="container vh-100 d-flex row align-items-center justify-content-center" ><h1>Loading ...</h1></div>
});

const LazyFavoris = Loadable({
  loader: () => import('./features/favoris'),
  loading: () => <div className="container vh-100 d-flex row align-items-center justify-content-center" ><h1>Loading ...</h1></div>
})



class App extends Component {



  componentDidMount() {
    this.props.fetchFavoris();
  }

  render() {
    return (
      <div className={"App d-flex flex-column " + Style.bgImg }>
        <Header />
        <Switch>
          <Route path="/connexion" render={ (props) => {
                return (
                  <Identifaction
                  />
                )
              } } />
          <Route path="/films" component={LazyFilms} />
          <Route path="/favoris" component={LazyFavoris} />
          <Route path="/formulaire" render={ (props) => {
                return (
                  <Formulaire
                  />
                )
              } } />
          <Redirect to="/films" />
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default withRouter(connect(null, {
  fetchFavoris
})(App));