import React from 'react';

const Loading = () => {
  return (
    <div className="w-100 d-flex vh-100 justify-content-center align-items-center mt-5">
      <img alt="loading gif" src="https://images-platform.99static.com/WGKaKpSHf5hBVwL4OuuNMp_w9qc=/100x100:900x900/500x500/top/smart/99designs-contests-attachments/82/82084/attachment_82084897" />
    </div>
  )
}
export default Loading;
