import React from 'react';

export class Register extends React.Component {
	/*   constructor(props) {
    super(props);
  } */

	render() {
		return (
			<div className="base-container" ref={this.props.containerRef}>
				<h2 className="header">Créez votre compte</h2>
				<div className="content">
					<div className="form">
						<div className="form-group">
							<input type="text" name="username" placeholder="Identifiant" />
						</div>
						<div className="form-group">
							<input type="text" name="email" placeholder="email" />
						</div>
						<div className="form-group">
							<input type="text" name="password" placeholder="Mot de passe" />
						</div>
					</div>
				</div>
				<div className="footer my-0">
					<button type="button" className="btn">
						Je crée mon compte
					</button>
				</div>
			</div>
		);
	}
}
