import React from 'react';
import './index.scss';
import { Login, Register } from './components/index';

class Identifaction extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLogginActive: true,
		};
	}

	/*  componentDidMount() {
    //Add .right by default
    this.rightSide.classList.add("right");
  } */

	changeState() {
		const { isLogginActive } = this.state;

		if (isLogginActive) {
			this.rightSide.classList.remove('right');
			this.rightSide.classList.add('left');
		} else {
			this.rightSide.classList.remove('left');
			this.rightSide.classList.add('right');
		}
		this.setState(prevState => ({ isLogginActive: !prevState.isLogginActive }));
	}

	render() {
		const { isLogginActive } = this.state;
		const current = isLogginActive ? "S'enregistrer" : 'Se connecter';
		const currentActive = isLogginActive ? 'Se connecter' : "S'enregistrer";
		return (
			<div className="App mx-5">
				<div className="login">
					<RightSide
						current={current}
						currentActive={currentActive}
						containerRef={ref => (this.rightSide = ref)}
						onClick={this.changeState.bind(this)}
					/>
					<div className="container" ref={ref => (this.container = ref)}>
						{isLogginActive && (
							<Login containerRef={ref => (this.current = ref)} />
						)}
						{!isLogginActive && (
							<Register containerRef={ref => (this.current = ref)} />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const RightSide = props => {
	return (
		<div
			className="right-side"
			ref={props.containerRef}
			onClick={props.onClick}>
			<div className="inner-container">
				<div className="text">{props.current}</div>
			</div>
		</div>
	);
};

export default Identifaction;
