/** @format */

import React, { Component } from 'react';
import Style from './Footer.module.scss';

export default class Footer extends Component {
	render() {
		return (
			<div className={Style.container}>
				<footer className="p-3 bg-dark text-white text-center">
					<span>
						2020 - Fait par{' '}
						<a
							className="text-reset"
							href="https://younes-haidri.fr"
							rel="noopener noreferrer"
							target="_blank">
							Younes HAIDRI
						</a>{' '}
						- Merci dyma.fr pour les cours en ligne
					</span>
				</footer>
			</div>
		);
	}
}
