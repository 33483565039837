import * as axios from 'axios';

export const apiMovie = axios.create({
	baseURL: 'https://api.themoviedb.org/4',
});

apiMovie.interceptors.request.use(req => {
	req.headers['Authorization'] =
		'Bearer eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJlOWYwMzRlYjNlODhhNGIwNWFhYmM1MThmNTI2MjE1ZCIsInN1YiI6IjVmODZmNDJhZDRiOWQ5MDAzODI1NmQ0NiIsInNjb3BlcyI6WyJhcGlfcmVhZCJdLCJ2ZXJzaW9uIjoxfQ.LA3TDBtpuqljWflhIBqEAzjI-FogTHijw-5fBnRjD1c';
	return req;
});

export const apiMovieMap = m => ({
	img: `https://image.tmdb.org/t/p/w500` + m.poster_path,
	title: m.title,
	details: `${m.release_date} | ${m.vote_average}/10 | nombre de vote : ${m.vote_count}`,
	description: m.overview,
});
// eslint-disable-next-line
export default {
	searchMovies: filter => {
		const query =
			'?' +
			Object.keys(filter)
				.map(k => `${k}=${filter[k]}&`)
				.join('');
		return apiMovie
			.get(`/search/movie` + query)
			.then(response => response.data.results)
			.then(moviesApi => moviesApi.map(apiMovieMap)); // ERROR probable
	},
};
