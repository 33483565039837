import React from 'react';

export class Login extends React.Component {
	/*   constructor(props) {
    super(props);
  } */

	render() {
		return (
			<div className="base-container" ref={this.props.containerRef}>
				<h2 className="header">Identifiez-vous</h2>
				<div className="content">
					<div className="form">
						<div className="form-group">
							<input type="text" name="username" placeholder="Identifiant" />
						</div>
						<div className="form-group">
							<input
								type="password"
								name="password"
								placeholder="Mot de passe"
							/>
						</div>
					</div>
				</div>
				<div className="footer">
					<button type="button" className="btn ml-auto">
						Je me connecte
					</button>
				</div>
			</div>
		);
	}
}
