import React, { Component } from 'react';
import Style from './Header.module.scss';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import Logo from '../../assets/img/AlloMovieLogo-blanc.png';


export default class Header extends Component {


  render() {
    return (
      <header className={Style.container} >
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="shadow">
          <Navbar.Brand href="/">
            <img
              src= { Logo }
              width="70px"
              className="d-inline-block align-top"
              alt="Allo Movies logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="navbar-nav ml-auto align-items-center">
                <NavLink to="/films" className="nav-link ml-auto" activeClassName="active">Films</NavLink>
                <NavLink to="/favoris" className="nav-link ml-auto" activeClassName="active">Favoris</NavLink>
                <NavLink to="/formulaire" className="nav-link ml-auto" activeClassName="active">Formulaire</NavLink>
                <NavLink to="/connexion" className="nav-link ml-auto" activeClassName="active">S'identifier/<br/>Se connecter</NavLink>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
    );
  }
}